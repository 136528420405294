<template>
    <div class="cabinets">
        <store-product 
            :data_products="data_products">
        </store-product>
        <!-- Footer -->
        <main-footer></main-footer>
    </div>
</template>

<script>
export default {
	metaInfo: {
		title: 'Archivadores',
		meta: [
			// Ummami Express
			{ name: 'description', content:'Fabrica de muebles para oficinas y estanterías metálicas, escritorios para oficinas, sillas para oficinas, divisiones para oficinas, estanteria metalica, bucaramanga, más de 30 años de experiencia.' },
            { name: 'keywords', content:'arvhicadores, Archivadores, arvhicadores bucaramanga, Archivadores bucaramanga, industrias pico, muebles oficina, muebles oficina Bucaramanga, muebles para oficina Bucaramanga, estantería metálica, estanterías metálicas, fabricas de estanterías metálicas, divisiones para oficina Bucaramanga, escritorios gerenciales Bucaramanga, escritorios para gerencia, Archivadores metálicos, fabrica de muebles para oficina, sillas para oficinas Bucaramanga, diseño de oficinas Bucaramanga, muebles para recepción Bucaramanga' },
            { name: 'author', content:'Angecia de Marketing Digital Winketing | Sebastian Báez - Leader Programming Winketing' },
			// SOCIAL
			// OpenGraph data (Most widely used)
			{property: 'og:title', content: 'Archivadores'},
			{property: 'og:site_name', content: 'Industrias Pico'},
			{property: 'og:type', content: 'website'},
			{property: 'og:url', content: 'https://industriaspico.com'},
			{property: 'og:image', content: 'https://industriaspico.com/public_sb_images/logo-industrias-pico.png'},
			{property: 'og:description', content: 'Archivadores en bucaramanga, Fabrica de muebles para oficinas y estanterías metálicas, escritorios para oficinas, sillas para oficinas, divisiones para oficinas, estanteria metalica, bucaramanga, más de 30 años de experiencia.'},
			// Twitter card    
			{name: 'twitter:card', content: 'summary'},
			{name: 'twitter:site', content: 'https://industriaspico.com'},
			{name: 'twitter:title', content: 'Archivadores'},
			{name: 'twitter:description', content: 'Fabrica de muebles para oficinas y estanterías metálicas, escritorios para oficinas, sillas para oficinas, divisiones para oficinas, estanteria metalica, bucaramanga, más de 30 años de experiencia.' },
			// Your twitter handle, if you have one.
			// {name: 'twitter:creator', content: '@alligatorio'},
			{name: 'twitter:image:src', content: 'https://industriaspico.com/public_sb_images/logo-industrias-pico.png'},
			// Google / Schema.org markup:
			{itemprop: 'name', content: 'Archivadores'},
			{itemprop: 'description', content: 'Archivadores en bucaramanga, Fabrica de muebles para oficinas y estanterías metálicas, escritorios para oficinas, sillas para oficinas, divisiones para oficinas, estanteria metalica, bucaramanga, más de 30 años de experiencia.' },
			{itemprop: 'image', content: 'https://industriaspico.com/public_sb_images/logo-industrias-pico.png'}
		]
    },
    metaInfo() {
        return {
            title: this.page_title,
        }
    },
    data() {
        return {
            page_title: 'Archivadores',
            data_products: {
                title_page: 'Archivadores',
                types: [
                    { text: 'Gaveta', value: 'gaveta' },
                    { text: 'Aéreo', value: 'aereo' }
                ],
                products: [
                    {
                        name: 'Archivador de Gaveta',
                        description: `
                            <p>
                                Mueble con niveladores, cajonera y gavetas, especial para fólderes y documentos.
                                Tiene soporte de carga hasta de 60 carpetas en la gaveta mediana y de almacenamiento
                                inferior con divisiones y correderas independientes remachadas.   
                            </p>
                            <p>CARACTERÍSTICAS</p>
                            <ul class="pl-3">
                                <li class="mb-2">Medidas de 30 a 60 cm según el ancho.</li>
                                <li class="mb-2">De 90.1 a 160 cm según el alto.</li>
                                <li class="mb-2">El mueble se entrega armado.</li>
                                <li class="mb-2">Posee resistencia mecánica a la cortadura, flexión, impacto y torsión.</li>
                            </ul> 
                        `,
                        url_image: 'mobiliario-de-supermercado/archivadores/productos/gavetas/gaveta_2.jpg',
                        slides: [
                            'mobiliario-de-supermercado/archivadores/productos/gavetas/gaveta_2.jpg'
                        ],
                        price: '',
                        tags: ['gaveta']
                    },
                    {
                        name: 'Archivador Aéreo',
                        description: `
                            <p>
                                Este producto es una estructura ideal para pared. Está diseñado para ser instalado en una estructura fija; 
                                es usado como auxiliar para almacenamiento de documentos y carpetas.
                            </p>
                            <p>CARACTERÍSTICAS</p>
                            <ul class="pl-3">
                                <li class="mb-2">Medidas de 40 cm de alto, 90 cm de ancho y 32 cm de fondo.</li>
                                <li class="mb-2">Soporte de carga: 55 kilos por cajón.</li>
                                <li class="mb-2">El mueble se entrega armado.</li>
                            </ul> 
                        `,
                        url_image: 'mobiliario-de-supermercado/archivadores/productos/aereo/aereo_1.jpg',
                        slides: [
                            'mobiliario-de-supermercado/archivadores/productos/aereo/aereo_1.jpg'
                        ],
                        price: '',
                        tags: ['aereo']
                    }
                ]
            } 
        }
    },
    computed: {
        url_tag() {
            return this.$route.params.tag;
        }
    },
    watch: {
        url_tag() {
            if (this.url_tag == 'aereo') {
                this.page_title = 'Aéreo';
            } else {
                this.page_title = this.url_tag.replaceAll('-', ' ').capitalize();
            }
        }
    }
}
</script>